.select-container {
  position: relative;
  display: inline-block;
  /* margin-bottom: 20px; */
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #06652e;
  color: #F8FBFF;
  border: none;
  outline: none;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  width: 100%;
}

.custom-select-two {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #F8FBFF;
  color: #000;
  /* border: none; */
 
  outline: none;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  width: 100%;
}

.dropdown-arrow-two {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%) rotate(45deg);
  width: 0.6rem;
  height: 0.6rem;
  border: solid #000;
  border-width: 0 0.15rem 0.15rem 0;
}


.dropdown-arrow {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%) rotate(45deg);
  width: 0.6rem;
  height: 0.6rem;
  border: solid #F8FBFF;
  border-width: 0 0.15rem 0.15rem 0;
}

@media (max-width: 767px) {
  .select-container {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
  }
  
  .custom-select {
    font-size: 1rem;
    padding: 0.5rem 2rem 0.5rem 0.75rem;
    width: auto;
  }
  
  .dropdown-arrow {
    right: 0.75rem;
  }
}
