@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  font-family: 'Jost', sans-serif;
  /* background-color: #F2F2F2; */
  /* overflow: hidden; */
  overflow-x: hidden;
}