.pagination-container {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin-top: 1rem;
}

.pagination-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  padding: 0.5rem;
  background-color: #e0e0e0;
  color: #333;
}

.pagination-item-active {
  background-color: #06652e;
  color: #fff;
}

.pagination-item-disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.pagination-link {
  text-decoration: none;
  color: inherit;
}

.pagination-link-active {
  font-weight: bold;
}
